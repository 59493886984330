<template>
  <div class="pa-3">
    <v-alert
      v-if="company.remainingLimit && company.remainingLimit.amount < 1"
      type="info"
      dense
      color="danger"
      class="my-2">
      The current company has no remaining balance.
    </v-alert>
    <v-row>
      <v-col cols="12" lg="6" class="d-flex flex-column justify-space-between">
        <div>
          <h4 class="mt-2 ml-1 primary--text">Company Information</h4>
          <v-card class="d-flex my-1 pa-3 borderccc">
            <v-row class="pt-1 ma-0">
              <v-col cols="6" class="d-flex flex-column justify-space-between pa-0 ma-0">
                <div>
                  <span class="info-title">Company Name</span>
                  <span class="info-text">{{ company.name ? company.name : "---" }}</span>
                </div>
                <div>
                  <span class="info-title">Organization Number</span>
                  <span class="info-text">{{ company.organizationNumber ? company.organizationNumber : "---" }}</span>
                </div>
              </v-col>
              <v-col cols="6" class="pa-0 ma-0">
                <span class="info-title">Tenants</span>
                <div style="width: 100%">
                  <span v-for="(tenant, idx) in filteredTenantNames" :key="idx" class="info-text d-inline-block">
                    {{ company.tenants ? tenant : "---"
                    }}<span v-if="idx < filteredTenantNames.length - 1">,&nbsp;</span>
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>

        <div>
          <h4 class="mt-2 ml-1 primary--text">Contact Person</h4>
          <v-card class="my-1 pa-3 borderccc">
            <v-row class="pt-1 ma-0">
              <v-col cols="12" md="8" class="pa-0 ma-0">
                <span class="info-title">Full Name</span>
                <span class="info-text">{{ company.contactPersonName ? company.contactPersonName : "---" }}</span>
              </v-col>
              <v-col cols="12" md="4" class="pa-0 ma-0">
                <span class="info-title">Phone Number</span>
                <span class="info-text">{{ company.phone ? company.phone : "---" }}</span>
              </v-col>
            </v-row>
            <v-row class="pt-1 ma-0">
              <v-col class="pa-0 ma-0">
                <span class="info-title">E-Mail</span>
                <span class="info-text">{{ company.email ? company.email : "---" }}</span>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>

      <v-col cols="12" lg="6" class="d-flex flex-column justify-space-between">
        <div>
          <h4 class="mt-2 ml-1 primary--text">Reference</h4>
          <v-card class="my-1 pa-3 borderccc">
            <v-row class="pt-1 ma-0">
              <v-col class="pa-0 ma-0">
                <span class="info-title">E-Mail for Invoice or E-Invoice</span>
                <span class="info-text">{{ company.invoiceEmail ? company.invoiceEmail : "---" }}</span>
              </v-col>
            </v-row>
            <v-row class="pt-1 ma-0">
              <!-- <v-col class="pa-0 ma-0">
                <span class="info-title">IATA Number</span>
                <span class="info-text">{{
                  company.iataNumber ? company.iataNumber : "---"
                }}</span>
              </v-col> -->
              <v-col cols="12" md="8" class="pa-0 ma-0">
                <span class="info-title">Other / Branch</span>
                <span class="info-text">{{ company.reference ? company.reference : "---" }}</span>
              </v-col>
              <v-col cols="12" md="4" class="pa-0 ma-0">
                <span class="info-title">Fortnox Number</span>
                <span class="info-text">{{ company.fortnoxNumber ? company.fortnoxNumber : "---" }}</span>
              </v-col>
            </v-row>
          </v-card>
        </div>

        <div>
          <h4 class="mt-2 ml-1 primary--text">Finance</h4>
          <v-card class="my-1 pa-3 borderccc">
            <v-row class="pt-1 ma-0" cols="12" md="8">
              <v-col class="pa-0 ma-0">
                <span class="info-title">Allowed Days to Pay the Invoice</span>
                <span class="info-text">{{ company.allowedDaysToPay ? company.allowedDaysToPay : "---" }}</span>
              </v-col>
              <v-col class="pa-0 ma-0" cols="12" md="4">
                <span class="info-title">Limit</span>
                <span class="info-text">{{ company.limit.amount ? company.limit.plainText : "---" }}</span>
              </v-col>
            </v-row>
            <v-row class="pt-1 ma-0">
              <v-col class="pa-0 ma-0" cols="12" md="8">
                <span class="info-title">Automatically Add Invoice Fee</span>
                <span class="info-text">{{ company.automaticInvoiceFeeEnabled ? "YES" : "NO" }}</span>
              </v-col>

              <v-col class="pa-0 ma-0" cols="12" md="4">
                <span
                  class="info-title"
                  :class="
                    company.remainingLimit && company.remainingLimit.amount != 0 ? 'greeny--text' : 'primary--text'
                  "
                  >Remaning Limit</span
                >
                <span class="info-text">{{
                  company.remainingLimit && company.remainingLimit.amount ? company.remainingLimit.plainText : "---"
                }}</span>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-card v-if="company && company.comment" class="my-2 borderccc py-3" style="background-color: #fff6d9">
      <v-row>
        <v-col>
          <v-card-title class="black--text mt-0 pt-0"
            ><span class="text-sm-body-2 font-weight-bold">Internal Message</span></v-card-title
          >
          <v-card-subtitle class="black--text mb-0 pb-0"> {{ company.comment }}</v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>

    <h4 class="mt-6 ml-1 primary--text">Authorized Employee</h4>

    <div class="text-center">
      <v-progress-circular :size="50" color="primary" class="mt-2" v-if="loading" indeterminate></v-progress-circular>
    </div>
    <div v-if="!loading" style="padding: 2px">
      <v-card v-for="(item, index) in company.customers" :key="index" class="my-2 pa-3 borderccc">
        <v-row class="pt-0 ma-0">
          <v-col class="pa-0 ma-0">
            <v-icon dense aria-hidden="false" color="accent"> mdi-pound </v-icon>
            <span class="accent--text font-weight-bold">IATA Number</span>
            <span v-if="item.companyCustomerNumber" class="ml-2 font-weight-bold">
              {{ item.companyCustomerNumber }}
            </span>
            <span v-else class="ml-2 font-weight-bold">----</span>
          </v-col>
          <v-col class="pa-0 ma-0">
            <v-icon dense aria-hidden="false" color="accent"> mdi-account </v-icon>
            <span v-if="item.firstName || item.lastName" class="font-weight-bold">
              {{ item.firstName + " " + item.lastName }}
            </span>
            <span v-else class="ml-2 font-weight-bold">----</span>
          </v-col>
          <v-col class="pa-0 ma-0">
            <v-icon dense aria-hidden="false" color="accent"> mdi-email </v-icon>
            <span v-if="item.email" class="ml-2 font-weight-bold">{{ item.email }}</span>
            <span v-else class="ml-2 font-weight-bold">----</span>
          </v-col>
          <v-col class="pa-0 ma-0 col-2">
            <v-icon dense aria-hidden="false" color="accent"> mdi-phone </v-icon>
            <span v-if="item.phone" class="ml-2 font-weight-bold">{{ item.phone }}</span>
            <span v-else class="ml-2 font-weight-bold">----</span>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <h4 class="black--text mt-6 mb-2" style="margin-left: 4px">
      <span class="mango-red">Activity log </span>({{ company.events.length }})
    </h4>
    <v-row justify="space-between">
      <v-col cols="12" lg="12">
        <v-card style="margin-left: 2px; margin-right: 2px">
          <v-card-text class="elevation-1">
            <v-timeline dense align-top>
              <event-list :events="company.events" />
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EventList from "@/views/Orders/components/EventList.vue";
import { mapGetters } from "vuex";

export default {
  components: { EventList },
  props: {
    company: [],
    loading: Boolean,
  },
  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
    }),
    filteredTenantNames() {
      return this.getTenantNames(this.company.tenants);
    },
  },
  methods: {
    getTenantNames(tenantIds) {
      return this.tenants.filter((tenant) => tenantIds.includes(tenant.id)).map((tenant) => tenant.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.info-title {
  font-weight: bold;
  color: #5e87c1;
  font-size: 14px;
  display: block;
}

.info-text {
  color: #444;
  font-weight: 500;
  display: block;
  font-size: 15px;
}
</style>
